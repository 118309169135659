import { Table, SystemStyleInterpolation, Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useMemo } from 'react'

import { initializeEquipmentFilters } from 'components/modules/Equipment/Filters/config'
import EquipmentFilters from 'components/modules/Equipment/Filters/EquipmentFilters'
import { TABLE_HEADERS } from 'components/modules/Equipment/List/EquipmentTable/config'
import EquipmentTableBody from 'components/modules/Equipment/List/EquipmentTable/EquipmentTableBody'
import TableContainer from 'components/modules/Table/TableContainer'
import TableError from 'components/modules/Table/TableError'
import TableErrorTBody from 'components/modules/Table/TableErrorTBody'
import TableHeader from 'components/modules/Table/TableHeader'
import TableLoading from 'components/modules/Table/TableLoading'
import TablePagination from 'components/modules/Table/TablePagination'
import TableSearch from 'components/modules/Table/TableSearch'
import TableTotalCount from 'components/modules/Table/TableTotalCount'
import filterByProp from 'components/modules/Table/utils/filterByProp'
import filterBySearch from 'components/modules/Table/utils/filterBySearch'
import sortBySortOrderAndColumn from 'components/modules/Table/utils/sortBySortOrderAndColumn'
import getEquipmentList from 'database/equipment/getEquipmentList'
import useTable from 'hooks/useTable'
import useTablePage from 'hooks/useTablePage'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

const styles = {
  container: {
    border: '1px solid',
    borderColor: 'gray.300',
  },
  wrapper: {
    position: 'relative',
  },
} as const satisfies SystemStyleInterpolation

const TAG = 'EquipmentTable'

const EquipmentTable: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    pageIndex,
    pageSize,
    sortColumn,
    sortOrder,
    search,
    filters: tableFilters,
    setFilter,
    resetFilters,
    nextPage,
    previousPage,
    changePageSize,
    changeSort,
    setSearch,
  } = useTable()

  const { erpId } = useBusinessUnit()
  const response = useQuery(['getEquipmentList', erpId], getEquipmentList)

  const filteredEquipment = useMemo(() => {
    const allEquipments = response.data?.items || []
    return allEquipments
      .filter(filterBySearch(search))
      .filter(filterByProp<Database.EquipmentListItem>(tableFilters))
      .sort(
        sortBySortOrderAndColumn<Database.EquipmentListItem>(
          sortColumn as keyof Database.EquipmentListItem,
          sortOrder,
          'model',
        ),
      )
  }, [response.data?.items, search, sortColumn, sortOrder, tableFilters])

  const filteredCount = filteredEquipment.length
  const errorOcurred = (response.isSuccess && !filteredCount) || response.isError
  const page = useTablePage(filteredEquipment, pageSize, pageIndex)

  const filters = useMemo(() => initializeEquipmentFilters(tableFilters, t), [tableFilters, t])

  return (
    <>
      <EquipmentFilters filters={filters} setFilter={setFilter} resetFilters={resetFilters} />
      <Box data-pagination-scroll={true} data-testid={TAG} {...styles.container}>
        <TableSearch onChange={setSearch} search={search} />

        <Box {...styles.wrapper}>
          <TableTotalCount
            totalCount={response.data?.items?.length || 0}
            filteredCount={filteredCount}
            label="components.table.total-equipment"
          />
          <TableContainer isFetching={response.isFetching}>
            <Table variant="striped">
              <TableHeader
                headers={TABLE_HEADERS}
                changeSort={changeSort}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
              />
              {response.isLoading && <TableLoading columns={TABLE_HEADERS.length} />}
              {response.isSuccess && !!filteredCount && <EquipmentTableBody rows={page} />}
              {errorOcurred && <TableErrorTBody columns={TABLE_HEADERS.length} />}
            </Table>
          </TableContainer>

          <TablePagination
            pageIndex={pageIndex}
            nextPage={nextPage}
            previousPage={previousPage}
            pageSize={pageSize}
            changePageSize={changePageSize}
            totalCount={filteredCount}
          />

          {errorOcurred && <TableError error={response.error} />}
        </Box>
      </Box>
    </>
  )
}

export default EquipmentTable
