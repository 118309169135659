import type { Headers } from 'components/modules/Table/TableHeader'

export const TABLE_HEADERS = [
  ['serialNumber', 'pages.equipment.serialNumber', { minWidth: '8rem' }],
  ['model', 'pages.equipment.model', { minWidth: '17rem' }],
  ['customerSerialNumber', 'pages.equipment.customerSerialNumber', { minWidth: '10rem' }],
  ['site', 'pages.equipment.site', { minWidth: '9rem' }],
  ['makeYear', 'pages.equipment.makeYear', { minWidth: '8rem' }],
  ['isConnected', 'pages.equipment.isConnected', { minWidth: '8rem', textAlign: 'center' }],
  ['isSmartDrive', 'pages.equipment.isSmartDrive', { minWidth: '8rem', textAlign: 'center' }],
  ['process', 'pages.equipment.process', { minWidth: '14rem' }],
] as const satisfies Headers<keyof Database.EquipmentListItem>
