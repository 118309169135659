import { Tbody } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import { FunctionComponent, memo } from 'react'

import EquipmentTableRow from 'components/modules/Equipment/List/EquipmentTable/EquipmentTableRow'

interface EquipmentTableBodyProps {
  rows: ReadonlyArray<Database.EquipmentListItem>
}

const TAG = 'EquipmentTableBody'

const EquipmentTableBody: FunctionComponent<EquipmentTableBodyProps> = ({ rows }) => (
  <Tbody>
    {rows.map((row) => (
      <EquipmentTableRow key={row.serialNumber} {...row} />
    ))}
  </Tbody>
)

EquipmentTableBody.displayName = TAG

export default memo(EquipmentTableBody, isEqual)
