
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Box, Container, SystemStyleInterpolation } from '@chakra-ui/react'
import getT from 'next-translate/getT'
import useTranslation from 'next-translate/useTranslation'

import getCompanyName from 'commercetools/utils/getCompanyName'
import EquipmentTable from 'components/modules/Equipment/List/EquipmentTable'
import HeroImage from 'components/modules/Hero/HeroImage'
import getRevalidate from 'config/revalidate'
import withPageStaticProps from 'utils/next/withPageStaticProps'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface StartPageProps {}

const TAG = 'Home'

const styles = {
  container: {
    w: 'full',
    maxW: 'desktop',
    p: 0,
  },
  wrapper: {
    px: { base: 6, lg: 10 },
    pb: { base: 6, lg: 10 },
  },
} as const satisfies SystemStyleInterpolation

const HERO_IMAGE_SRC = '/images/fleet-overview-hero-image.jpg'

const StartPage: NextPageWithLayout<StartPageProps> = () => {
  const { t } = useTranslation()
  const { current } = useBusinessUnit()

  const companyName = getCompanyName(current)
  const title = companyName
    ? t('pages.fleet-overview.title', { company: companyName })
    : t('pages.fleet-overview.pageTitle')

  return (
    <Container data-testid={TAG} {...styles.container}>
      <HeroImage title={title} src={HERO_IMAGE_SRC} color="white" mb={10} />
      <Box {...styles.wrapper}>
        <EquipmentTable />
      </Box>
    </Container>
  )
}

export default StartPage

 const _getStaticProps = withPageStaticProps<StartPageProps>(async ({ locale }) => {
  if (locale === 'default' || !locale) {
    return { notFound: true }
  }

  const t = await getT(locale, 'common')
  const title = t('pages.fleet-overview.pageTitle')
  const description = t('pages.fleet-overview.pageDescription')

  return {
    props: {
      layout: {
        props: { seo: { title, description, image: { title, url: HERO_IMAGE_SRC, contentType: 'image/jpeg' } } },
      },
    },
    revalidate: getRevalidate(),
  }
})


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  