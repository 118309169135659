import type { QueryFunctionContext } from '@tanstack/react-query'

import { RoutePaths } from 'config/routes'
import apiFetch from 'utils/apiFetch'

type Arguments = QueryFunctionContext<[queryFnName: 'getEquipmentList', erpId: Database.ERPId]>

interface EquipmentListResponse {
  items: Database.EquipmentListItem[]
  total: number
}

function getEquipmentList({ signal, queryKey: [, erpId] }: Arguments): Promise<EquipmentListResponse> {
  if (!erpId) {
    return Promise.resolve({ items: [], total: 0 })
  }

  const params = new URLSearchParams({ erpId }).toString()
  const url = `${RoutePaths.apiEquipmentList}?${params}`
  return apiFetch<EquipmentListResponse>(url, { signal })
}

export default getEquipmentList
