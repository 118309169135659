import { Td, Tr, Icon, LinkBox, LinkOverlay, SystemStyleInterpolation } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import CheckIcon from 'assets/check.svg?component'
import SmartDriveIcon from 'assets/smartDrive.svg?component'
import Link from 'components/elements/Link'
import { Categories, isCategoryName } from 'config/categories'
import { EMPTY_VALUE } from 'config/const'
import { RoutePaths } from 'config/routes'
import getEquipmentDetails from 'database/equipment/getEquipmentDetails'
import usePrefetch from 'hooks/usePrefetch'
import assignParamsToUrl from 'utils/assignParamsToUrl'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface EquipmentTableRowProps extends Database.EquipmentListItem {}

const TAG = 'EquipmentTableRow'

const styles = {
  link: {
    textDecoration: 'none',
    color: 'primary.red',
    fontWeight: 700,
  },
  tr: {
    _hover: {
      cursor: 'pointer',
      td: { bgColor: 'gray.200 !important' },
    },
  },
} as const satisfies SystemStyleInterpolation

const EquipmentTableRow: FunctionComponent<EquipmentTableRowProps> = ({
  serialNumber,
  model,
  customerSerialNumber,
  site,
  makeYear,
  isConnected,
  isSmartDrive,
  process,
}) => {
  const { t } = useTranslation()

  const { erpId } = useBusinessUnit()
  const prefetch = usePrefetch(getEquipmentDetails, ['getEquipmentDetails', erpId, serialNumber])

  const categoryName = Categories[process]

  return (
    <LinkBox as={Tr} data-link="true" {...prefetch} {...styles.tr}>
      <Td>
        <Link
          {...styles.link}
          rel="noopener noreferrer"
          href={assignParamsToUrl(RoutePaths.equipment, { slug: serialNumber })}
        >
          {serialNumber}
        </Link>
      </Td>
      <Td>{model}</Td>
      <Td>
        <LinkOverlay
          as={NextLink}
          href={assignParamsToUrl(RoutePaths.equipment, { slug: serialNumber })}
          aria-label={serialNumber}
        >
          {customerSerialNumber ?? EMPTY_VALUE}
        </LinkOverlay>
      </Td>
      <Td>{site}</Td>
      <Td>{makeYear ?? EMPTY_VALUE}</Td>
      <Td textAlign="center">{isConnected && <Icon as={CheckIcon} aria-label={t('pages.equipment.isConnected')} />}</Td>
      <Td textAlign="center">
        {isSmartDrive && <Icon as={SmartDriveIcon} aria-label={t('pages.equipment.isSmartDrive')} />}
      </Td>
      <Td>
        {isCategoryName(categoryName)
          ? t(`components.categories.${categoryName}.title`, void 0, { default: categoryName ?? process })
          : process}
      </Td>
    </LinkBox>
  )
}

EquipmentTableRow.displayName = TAG

export default EquipmentTableRow
